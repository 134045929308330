import { Outlet } from '@remix-run/react';

import { useTitle } from '../hooks/useTitle';
import { AdminView } from '../pages/Admin/AdminView';
import { Wrapper } from '../pages/Admin/Toolkit';
import { makeTitle } from '../utils/common';

function AdminToolkitShellRoute() {
  useTitle(makeTitle('Toolkit'));

  return (
    <AdminView notificationCenter>
      <Wrapper>
        <Outlet />
      </Wrapper>
    </AdminView>
  );
}

export const Component = AdminToolkitShellRoute;

export function clientLoader() {
  return null;
}
